import { de_InfoCommand, se_InfoCommand, } from "../protocols/Aws_restJson1";
import { getSerdePlugin } from "@smithy/middleware-serde";
import { Command as $Command } from "@smithy/smithy-client";
export { $Command };
export class InfoCommand extends $Command.classBuilder()
    .m(function (Command, cs, config, o) {
    return [
        getSerdePlugin(config, this.serialize, this.deserialize),
    ];
})
    .s("Tetris", "Info", {})
    .n("TetrisClient", "InfoCommand")
    .f(void 0, void 0)
    .ser(se_InfoCommand)
    .de(de_InfoCommand)
    .build() {
}
