import { TetrisClient, } from "./TetrisClient";
import { InfoCommand, } from "./commands/InfoCommand";
import { SayHelloCommand, } from "./commands/SayHelloCommand";
import { createAggregatedClient } from "@smithy/smithy-client";
const commands = {
    InfoCommand,
    SayHelloCommand,
};
export class Tetris extends TetrisClient {
}
createAggregatedClient(commands, Tetris);
